import { Quill } from 'react-quill'
import QuillMentionBlot from './QuillMentionBlot'
import { Keys } from './logic'
import { TextAreaAutocompleteOption } from './'

/* eslint-disable-next-line */
const insertMention = (option: TextAreaAutocompleteOption, quill: any, triggerCharPos: number, cursorPos: number): void => {
    const render = option
    if (render === null || render === undefined) {
        return
    }
    const prevMentionCharPos = triggerCharPos
    const newMentionChartPos = triggerCharPos

    quill.editor.deleteText(newMentionChartPos, cursorPos - newMentionChartPos, 'user')

    quill.editor.insertEmbed(prevMentionCharPos, QuillMentionBlot.blotName, render, 'user')
    quill.editor.insertText(prevMentionCharPos + 1, ' ', 'user')
    quill.editor.setSelection(prevMentionCharPos + 2, 'user')
}
/* eslint-disable-next-line */
const addKeyboardBindings = (quill: any, selectHandler: () => boolean, escapeHandler: () => boolean, upHandler: () => boolean, downHandler: () => boolean) => {
    quill.keyboard.addBinding({ key: Keys.TAB }, selectHandler)
    quill.keyboard.bindings[Keys.TAB].unshift(quill.keyboard.bindings[Keys.TAB].pop())
    quill.keyboard.addBinding({ key: Keys.ENTER }, selectHandler)
    quill.keyboard.bindings[Keys.ENTER].unshift(quill.keyboard.bindings[Keys.ENTER].pop())
    // CLOSE SUGGESTIONS WITH KEYBOARD
    quill.keyboard.addBinding({ key: Keys.ESCAPE }, escapeHandler)
    // NAVIGATE SUGGESTIONS WITH KEYBOARD
    quill.keyboard.addBinding({ key: Keys.UP }, upHandler)
    quill.keyboard.addBinding({ key: Keys.DOWN }, downHandler)
}

const registerBlots = (): void => {
    Quill.register(QuillMentionBlot)
}

export default {
    insertMention,
    addKeyboardBindings,
    registerBlots,
}
