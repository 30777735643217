import * as Quill from 'quill'

const getMentionCharIndex = (text: string, mentionDenotationChars: string[] = []): { trigger: string; mentionCharIndex: number } => {
    return mentionDenotationChars.reduce(
        (prev: { trigger: string; mentionCharIndex: number }, trigger: string) => {
            const mentionCharIndex = text.lastIndexOf(trigger)

            if (mentionCharIndex > prev.mentionCharIndex) {
                return {
                    trigger,
                    mentionCharIndex,
                }
            }
            return {
                trigger: prev.trigger,
                mentionCharIndex: prev.mentionCharIndex,
            }
        },
        { trigger: '', mentionCharIndex: -1 }
    )
}

const hasValidChars = (text: string, allowedChars: RegExp | undefined): boolean | undefined => {
    return allowedChars?.test(text)
}

const hasValidMentionCharIndex = (mentionCharIndex: number, text: string, isolateChar: boolean): boolean => {
    if (mentionCharIndex > -1) {
        return !(isolateChar && !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g)))
    }
    return false
}

// eslint-disable-next-line
const getCursorPosition = (quillEditor: any): number | null => {
    const range = quillEditor.getSelection()
    if (range == null) return null
    return range.index
}

// eslint-disable-next-line
const getTextBeforeCursor = (quillEditor: any, cursorPos: number, maxChars = 0): string => {
    const startPos = Math.max(0, cursorPos - maxChars)
    return quillEditor.getText(startPos, cursorPos - startPos)
}

const getSearchComponents = (
    textBeforeCursor: string,
    cursorPos: number,
    mentionCharIndex: number,
    trigger: string,
    allowedChars: RegExp | undefined,
    isolateCharCharacter = false,
    minChars = 0
): null | { textAfter: string; triggerCharPos: number } => {
    if (hasValidMentionCharIndex(mentionCharIndex, textBeforeCursor, isolateCharCharacter)) {
        const triggerCharPos = cursorPos - (textBeforeCursor.length - mentionCharIndex)
        const textAfter = textBeforeCursor.substring(mentionCharIndex + trigger.length)
        if (textAfter.length >= minChars && hasValidChars(textAfter, allowedChars)) {
            return { textAfter, triggerCharPos }
        } else {
            return null
        }
    } else {
        return null
    }
}

const getSuggestionsPosition = (
    offsetLeft = 0,
    offsetTop = 0,
    mentionCharPosInContainer: Quill.BoundsStatic,
    mentionContainer: HTMLDivElement,
    containerPos: Quill.BoundsStatic,
    triggerCharPos: number,
    parentContainerWidth: number
): { top: number; left: number; renderToTop: boolean; childrenContentHeight: number } => {
    const containerHeight = mentionContainer.offsetHeight

    // ===== Handle HORIZONTAL positioning ======
    let leftPos = offsetLeft
    // Attempt to align the mention container with the LEFT of the mention char
    leftPos += mentionCharPosInContainer.left
    // default to the RIGHT if the LEFT is not visible
    if (containerRightIsNotVisible(mentionContainer, leftPos, containerPos, parentContainerWidth)) {
        const containerWidth = mentionContainer.offsetWidth + offsetLeft
        const quillWidth = containerPos.width
        leftPos += quillWidth - containerWidth
    }

    // ===== Handle VERTICAL positioning ======
    // Attempt to align the mention container with the top of the quill editor
    let topPos = offsetTop
    // Attempt to align the mention container with the BOTTOM of the mention char
    topPos += mentionCharPosInContainer.bottom
    // default to the TOP if the BOTTOM is not visible
    if (containerBottomIsNotVisible(mentionContainer, topPos, containerPos)) {
        if (containerTopIsNotVisible(mentionCharPosInContainer, containerHeight, containerPos, offsetTop)) {
            const overMentionCharPos = offsetTop * -1
            topPos = overMentionCharPos - containerHeight
        }
    }

    const positionMeta = {
        top: topPos,
        left: leftPos,
        renderToTop: topPos >= 0,
        childrenContentHeight: 0,
    }

    if (mentionContainer.children && mentionContainer.children.length > 0) {
        positionMeta.childrenContentHeight = (mentionContainer.children[0] as HTMLElement).offsetHeight * mentionContainer.children.length
    }
    return positionMeta
}

const containerBottomIsNotVisible = (container: HTMLDivElement, topPos: number, containerPos: Quill.BoundsStatic) => {
    const mentionContainerBottom = topPos + container.offsetHeight + containerPos.top
    return mentionContainerBottom > window.pageYOffset + window.innerHeight
}

const containerTopIsNotVisible = (triggerCharPos: Quill.BoundsStatic, containerHeight: number, containerPos: Quill.BoundsStatic, offsetTop: number) => {
    const topPos = triggerCharPos.top - (containerHeight + offsetTop)
    return topPos + containerPos.top <= 0
}

const containerRightIsNotVisible = (container: HTMLDivElement, leftPos: number, containerPos: Quill.BoundsStatic, parentContainerWidth: number) => {
    const rightPos = container.offsetWidth + containerPos.left // margin
    const browserWidth = parentContainerWidth
    return rightPos > browserWidth
}

const navigateInScroll = (mentionList: HTMLDivElement, itemNavIndex: number, maxHeight: number): void => {
    let scrollItemInView = false
    if (mentionList.children && mentionList.children.length > 0) {
        scrollItemInView = (mentionList.children[0] as HTMLDivElement).offsetHeight * mentionList.children.length > maxHeight
    }

    if (scrollItemInView) {
        const itemHeight = (mentionList.childNodes[itemNavIndex] as HTMLDivElement).offsetHeight
        const itemPos = itemNavIndex * itemHeight
        const containerTop = mentionList.scrollTop
        const containerBottom = containerTop + mentionList.offsetHeight

        if (itemPos < containerTop) {
            // Scroll up if the item is above the top of the container
            mentionList.scrollTop = itemPos
        } else if (itemPos > containerBottom - itemHeight) {
            // scroll down if any part of the element is below the bottom of the container
            mentionList.scrollTop += itemPos - containerBottom + itemHeight
        }
    }
}

export const Keys = {
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    UP: 38,
    DOWN: 40,
}

export default {
    getMentionCharIndex,
    hasValidChars,
    hasValidMentionCharIndex,
    getTextBeforeCursor,
    getSearchComponents,
    getCursorPosition,
    getSuggestionsPosition,
    navigateInScroll,
    Keys,
}
