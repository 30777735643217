import { Quill } from 'react-quill'

const Embed = Quill.import('blots/embed')

class MentionBlot extends Embed {
    static tagName = 'mention'
    static blotName = 'mention'
    static className = 'mention'
    static triggerCharTag = 'span'

    /* eslint-disable-next-line */
    static create(data: any): HTMLElement {
        const node = super.create()
        const triggerCharElement = document.createElement(MentionBlot.triggerCharTag)
        triggerCharElement.className = 'ql-mention-denotation-char'
        triggerCharElement.innerHTML = data.trigger
        node.appendChild(triggerCharElement)
        node.innerHTML += data.value
        return MentionBlot.setDataValues(node, data)
    }

    /* eslint-disable-next-line */
    static setDataValues(element: HTMLElement, data: any): HTMLElement {
        const domNode = element
        Object.keys(data).forEach((key) => {
            if (typeof data[key] !== 'object') domNode.dataset[key] = data[key]
            else domNode.dataset[key] = JSON.stringify(data[key])
        })
        return domNode
    }

    static value(domNode: HTMLElement): DOMStringMap {
        return domNode.dataset
    }
}

export default MentionBlot
